import * as _ from "lodash";
import { merge, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { ILgFeatures, INavNode, IUser } from "@logex/framework/lg-application";
import { HelpTooltip } from "./types";
import { ApplicationPermissions } from "./app-permissions";
import { AppFeatures, HidePagesFeatureConfiguration } from "./app-features";
import { RulesDialogService } from "./dialogs/edit-rules-dialog/services/rules-dialog.service";
import { RuleSection } from "@shared/dialogs/rules-dialog/types/constants";
import { FLEXIBLE_PAGE_DATA_KEY, IFlexibleLayoutsNavigation } from "@logex/flexible";


const navTree = function (rulesDialog: RulesDialogService, features: ILgFeatures, user: IUser): INavNode[] {
    return [
        {
            path: "",
            noBreadcrumb: true,
            id: "dashboard",
            pathMatch: "full",
        },

        {
            path: "data",
            id: "data",
            noNumber: true,
            lid: "APP._Navigation.DataManagement",

            children: [
                {
                    path: "source-data",
                    id: "source-data",
                    noNumber: true,
                    lid: "APP._Navigation.SourceData",
                    permissions: [ApplicationPermissions.VIEW_SOURCE_DATA],
                    data: {
                        expanded: true,
                    },
                    children: [
                        {
                            path: "production",
                            id: "source-data-production",
                            lid: "APP._Navigation.SourceDataProduction",
                            data: {
                                helpTooltipId: HelpTooltip.SourceDataProduction,
                                navigable: true,
                            },
                            children: [
                                {
                                    path: "data",
                                    lid: "APP._Navigation.SourceDataData",
                                    id: "source-data-production-data",
                                    children: [
                                        {
                                            path: "activity-product",
                                            lid: "APP._Navigation.SourceDataActivityProduct",
                                            id: "source-data-activity-product",
                                        },
                                        {
                                            path: "aggregated-production",
                                            lid: "APP._Navigation.SourceDataAggregatedProduction",
                                            id: "source-data-aggregated-production",
                                        },
                                    ]
                                },
                                {
                                    path: "reference-data",
                                    lid: "APP._Navigation.SourceDataReferenceData",
                                    id: "source-data-production-reference-data",
                                    children: [
                                        {
                                            path: "activity",
                                            lid: "APP._Navigation.SourceDataProductionReferenceDataActivity",
                                            id: "source-data-production-reference-data-activity",
                                        },
                                        {
                                            path: "specialty",
                                            lid: "APP._Navigation.SourceDataProductionReferenceDataSpecialty",
                                            id: "source-data-production-reference-data-specialty",
                                        },
                                        {
                                            path: "organisation-label",
                                            lid: "APP._Navigation.SourceDataProductionReferenceDataOrganisationLabel",
                                            id: "source-data-production-reference-data-organisation-label",
                                        },
                                    ]
                                },
                            ]
                        },
                        {
                            path: "financial",
                            id: "source-data-financial",
                            lid: "APP._Navigation.SourceDataFinancial",
                            data: {
                                helpTooltipId: HelpTooltip.SourceDataFinancial,
                                navigable: true,
                            },
                            children: [
                                {
                                    path: "data",
                                    lid: "APP._Navigation.SourceDataData",
                                    children: [
                                        {
                                            path: "account-cost",
                                            lid: "APP._Navigation.SourceDataAccountCost",
                                            id: "source-data-account-cost",
                                        },
                                    ]
                                },
                                {
                                    path: "reference-data",
                                    lid: "APP._Navigation.SourceDataReferenceData",
                                    children: [
                                        {
                                            path: "cost-centre-structure",
                                            lid: "APP._Navigation.SourceDataCostCentreStructure",
                                            id: "source-data-cost-centre-structure",
                                        },
                                        {
                                            path: "account-structure",
                                            lid: "APP._Navigation.SourceDataAccountStructure",
                                            id: "source-data-account-structure",
                                        },
                                        {
                                            path: "account-dimension",
                                            lid: "APP._Navigation.SourceDataAccountDimension",
                                            id: "source-data-account-dimension",
                                        },
                                    ]
                                }
                            ]
                        },
                        {
                            path: "human-resources",
                            id: "source-data-human-resources",
                            lid: "APP._Navigation.SourceDataHumanResources",
                            data: {
                                helpTooltipId: HelpTooltip.SourceDataHumanResource,
                                navigable: true,
                            },
                            children: [
                                {
                                    path: "data",
                                    lid: "APP._Navigation.SourceDataData",
                                    children: [
                                        {
                                            path: "human-resource-cost",
                                            lid: "APP._Navigation.SourceDataHumanResourceCost",
                                            id: "source-data-human-resource-cost",
                                        },
                                        {
                                            path: "job-plan-structure",
                                            lid: "APP._Navigation.SourceDataJobPlanStructure",
                                            id: "source-data-job-plan-structure",
                                        },
                                    ]
                                },
                                {
                                    path: "reference-data",
                                    lid: "APP._Navigation.SourceDataReferenceData",
                                    children: [
                                        {
                                            path: "job-title-structure",
                                            lid: "APP._Navigation.SourceDataJobTitleStructure",
                                            id: "source-data-job-title-structure",
                                        },
                                        {
                                            path: "employee",
                                            lid: "APP._Navigation.SourceDataEmployee",
                                            id: "source-data-employee",
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            path: "other-resources",
                            id: "source-data-other-resources",
                            lid: "APP._Navigation.SourceDataOtherResources",
                            data: {
                                helpTooltipId: HelpTooltip.SourceDataOtherResource,
                                navigable: true,
                            },
                            children: [
                                {
                                    path: "data",
                                    lid: "APP._Navigation.SourceDataData",
                                    children: [
                                        {
                                            path: "equipment-cost",
                                            lid: "APP._Navigation.SourceDataEquipmentCost",
                                            id: "source-data-equipment-cost",
                                        },
                                        {
                                            path: "material-cost",
                                            lid: "APP._Navigation.SourceDataMaterialCost",
                                            id: "source-data-material-cost"
                                        }
                                    ]
                                }
                            ],
                        },
                    ]
                },
                {
                    path: "characteristics",
                    id: "settings-characteristics",
                    lid: "APP._Navigation.SettingsCharacteristics",
                    permissions: [ApplicationPermissions.VIEW_CHARACTERISTICS, ApplicationPermissions.VIEW_COST_CENTRE_CHARACTERISTICS],
                    data: {
                        expanded: true,
                    },
                    children: [
                        {
                            path: "/data/characteristics/production",
                            lid: "APP._Navigation.SettingsCharacteristicsProduction",
                            id: "settings-characteristics-production",
                            permissions: [ApplicationPermissions.VIEW_CHARACTERISTICS],
                            data: {
                                helpTooltipId: HelpTooltip.CharacteristicsProduction,
                            },
                        },
                        {
                            path: "/data/characteristics/cost-centre",
                            lid: "APP._Navigation.SettingsCharacteristicsCostCentre",
                            id: "settings-characteristics-cost-centre",
                            permissions: [ApplicationPermissions.VIEW_COST_CENTRE_CHARACTERISTICS],
                            data: {
                                helpTooltipId: HelpTooltip.CharacteristicsCostCentre,
                            },
                        },
                        {
                            path: "/data/characteristics/account",
                            lid: "APP._Navigation.SettingsCharacteristicsAccount",
                            id: "settings-characteristics-account",
                            permissions: [ApplicationPermissions.VIEW_CHARACTERISTICS],
                            data: {
                                helpTooltipId: HelpTooltip.CharacteristicsAccount,
                            },
                        },
                        {
                            path: "/data/characteristics/job-title",
                            lid: "APP._Navigation.SettingsCharacteristicsJobTitle",
                            id: "settings-characteristics-job-title",
                            permissions: [ApplicationPermissions.VIEW_CHARACTERISTICS],
                            data: {
                                helpTooltipId: HelpTooltip.CharacteristicsHumanResource,
                            },
                        },
                        {
                            path: "/data/characteristics/equipment",
                            lid: "APP._Navigation.SettingsCharacteristicsEquipment",
                            id: "settings-characteristics-equipment",
                            permissions: [ApplicationPermissions.VIEW_CHARACTERISTICS],
                            data: {
                                helpTooltipId: HelpTooltip.CharacteristicsEquipment,
                            },
                        },
                        {
                            path: "/data/characteristics/material",
                            lid: "APP._Navigation.SettingsCharacteristicsMaterial",
                            id: "settings-characteristics-material",
                            permissions: [ApplicationPermissions.VIEW_CHARACTERISTICS],
                            data: {
                                helpTooltipId: HelpTooltip.CharacteristicsMaterial,
                            },
                        },
                        {
                            path: "/data/characteristics/resource",
                            lid: "APP._Navigation.SettingsCharacteristicsResource",
                            id: "settings-characteristics-resource",
                            permissions: [ApplicationPermissions.VIEW_CHARACTERISTICS],
                            data: {
                                helpTooltipId: HelpTooltip.CharacteristicsResource,
                            },
                        },
                        {
                            path: "/data/characteristics/task",
                            lid: "APP._Navigation.SettingsCharacteristicsTask",
                            id: "settings-characteristics-task",
                            permissions: [ApplicationPermissions.VIEW_CHARACTERISTICS],
                            data: {
                                helpTooltipId: HelpTooltip.CharacteristicsTask,
                            },
                        },
                    ]
                },
            ]
        },
        {
            path: "modeling",
            id: "modeling",
            lid: "APP._Navigation.Modeling",

            children: [
                {
                    path: "data-preparation",
                    id: "data-preparation",
                    lid: "APP._Navigation.DataPreparation",
                    anonymous: false,
                    permissions: [ApplicationPermissions.VIEW_COST_CENTRE_VALUES, ApplicationPermissions.VIEW_ORGANISATION_COST],
                    data: {
                        expanded: true,
                    },
                    children: [
                        {
                            path: "cost-centre-values",
                            lid: "APP._Navigation.CostCentreValues",
                            id: "cost-centre-values",
                            disabled: true,
                            anonymous: false,
                            permissions: [ApplicationPermissions.VIEW_COST_CENTRE_VALUES],
                        },
                        {
                            id: "organisation-cost",
                            path: "organisation-cost",
                            lid: "APP._Navigation.OrganisationCost",
                            anonymous: false,
                            permissions: [ApplicationPermissions.VIEW_ORGANISATION_COST],
                            data: {
                                helpTooltipId: HelpTooltip.OrganisationCost,
                            },
                        },
                        {
                            id: "optimize-data-analytics",
                            path: "optimize-data-analytics",
                            lid: "APP._Navigation.OptimizeDataAnalytics",
                            anonymous: false,
                            permissions: [ApplicationPermissions.VIEW_ORGANISATION_COST],
                            matchChildrenByQueryParams: ["layoutId"],
                            data: {
                                helpTooltipId: HelpTooltip.OptimizeDataAnalytics,
                                [FLEXIBLE_PAGE_DATA_KEY]: "optimize-data-analytics"
                            },
                        },
                    ]
                },
                {
                    path: "allocation-of-cost",
                    id: "allocation-of-cost",
                    lid: "APP._Navigation.AllocationOfCost",
                    anonymous: false,
                    permissions: [
                        ApplicationPermissions.VIEW_ALLOCATE_TO_RESOURCE,
                        ApplicationPermissions.VIEW_ALLOCATE_SUPPORT_COST,
                        ApplicationPermissions.VIEW_ALLOCATE_COST_ANALYTICS
                    ],
                    data: {
                        expanded: true,
                    },
                    children: [
                        {
                            id: "allocate-to-resource",
                            path: "allocate-to-resource",
                            lid: "APP._Navigation.AllocateToResource",
                            permissions: [ApplicationPermissions.VIEW_ALLOCATE_TO_RESOURCE],
                            anonymous: false,
                            data: {
                                helpTooltipId: HelpTooltip.AllocateToResource,
                            }
                        },
                        {
                            id: "allocate-support-cost",
                            path: "allocate-support-cost",
                            lid: "APP._Navigation.AllocateSupportCost",
                            permissions: [ApplicationPermissions.VIEW_ALLOCATE_SUPPORT_COST],
                            anonymous: false,
                            data: {
                                helpTooltipId: HelpTooltip.AllocateSupportCost,
                            },
                        },
                        {
                            path: "allocate-cost-analytics",
                            lid: "APP._Navigation.AllocateCostAnalytics",
                            id: "allocate-cost-analytics",
                            anonymous: false,
                            permissions: [ApplicationPermissions.VIEW_ALLOCATE_COST_ANALYTICS],
                            matchChildrenByQueryParams: ["layoutId"],
                            data: {
                                helpTooltipId: HelpTooltip.AllocateCostAnalytics,
                                [FLEXIBLE_PAGE_DATA_KEY]: "allocate-cost-analytics"
                            },
                        },
                    ]
                },
                {
                    path: "allocation-to-production",
                    id: "allocation-to-production",
                    lid: "APP._Navigation.AllocationToProduction",
                    anonymous: false,
                    permissions: [
                        ApplicationPermissions.VIEW_ALLOCATE_TO_PRODUCTION,
                        ApplicationPermissions.VIEW_PRODUCTION_VALUES,
                        ApplicationPermissions.VIEW_ALLOCATE_TO_PRODUCTION_ANALYTICS
                    ],
                    data: {
                        expanded: true,
                    },
                    children: [
                        {
                            id: "allocate-to-production",
                            path: "allocate-to-production",
                            lid: "APP._Navigation.AllocateToProduction",
                            permissions: [ApplicationPermissions.VIEW_ALLOCATE_TO_PRODUCTION],
                            anonymous: false,
                            data: {
                                helpTooltipId: HelpTooltip.AllocateToProduction,
                            }
                        },
                        {
                            path: "production-values",
                            lid: "APP._Navigation.ProductionValues",
                            id: "production-values",
                            anonymous: false,
                            permissions: [ApplicationPermissions.VIEW_PRODUCTION_VALUES],
                            data: {
                                helpTooltipId: HelpTooltip.ProductionValues,
                            },
                        },
                        {
                            path: "allocate-to-production-analytics",
                            lid: "APP._Navigation.AllocateToProductionAnalytics",
                            id: "allocate-to-production-analytics",
                            anonymous: false,
                            permissions: [ApplicationPermissions.VIEW_ALLOCATE_TO_PRODUCTION_ANALYTICS],
                            matchChildrenByQueryParams: ["layoutId"],
                            data: {
                                helpTooltipId: HelpTooltip.AllocateToProductionAnalytics,
                                [FLEXIBLE_PAGE_DATA_KEY]: "allocate-to-production-analytics"
                            },
                        },
                    ]
                },
            ]
        },


        {
            path: "rules",
            id: "rules",
            lid: "APP._Navigation.Rules",
            noNumber: true,

            children: [
                {
                    path: "data-preparation",
                    lid: "APP._Navigation.DataPreparation",
                    id: "rules-data-preparation",
                    anonymous: false,
                    permissions: [ApplicationPermissions.VIEW_COST_CENTRE_VALUES, ApplicationPermissions.VIEW_ORGANISATION_COST],
                    data: {
                        expanded: true,
                    },
                    onClickFn: () => {
                        rulesDialog.openRuleDialog(RuleSection.SubaccountSpecification);
                    },
                    children: [
                        {
                            path: "organisation-cost",
                            lid: "APP._Navigation.OrganisationCost",
                            id: "rules-data-preparation-organisation-cost",
                            anonymous: false,
                            permissions: [ApplicationPermissions.VIEW_ORGANISATION_COST],
                            data: {
                                expanded: true,
                            },
                            onClickFn: () => {
                                rulesDialog.openRuleDialog(RuleSection.SubaccountSpecification);
                            },
                            children: [
                                {
                                    path: "",
                                    lid: "APP._Navigation.SubaccountSpecification",
                                    id: "rules-data-preparation-organisation-cost-subaccount-specification",
                                    anonymous: false,
                                    permissions: [ApplicationPermissions.VIEW_ORGANISATION_COST],
                                    onClickFn: () => {
                                        rulesDialog.openRuleDialog(RuleSection.SubaccountSpecification);
                                    },
                                },
                                {
                                    path: "",
                                    lid: "APP._Navigation.AccountTransfer",
                                    id: "rules-data-preparation-organisation-cost-account-transfer",
                                    anonymous: false,
                                    permissions: [ApplicationPermissions.VIEW_ORGANISATION_COST],
                                    onClickFn: () => {
                                        rulesDialog.openRuleDialog(RuleSection.AccountTransfer);
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "allocation-of-cost",
                    lid: "APP._Navigation.AllocationOfCost",
                    id: "rules-allocation-of-cost",
                    anonymous: false,
                    permissions: [
                        ApplicationPermissions.VIEW_ALLOCATE_TO_RESOURCE,
                        ApplicationPermissions.VIEW_ALLOCATE_SUPPORT_COST,
                        ApplicationPermissions.VIEW_ALLOCATE_COST_ANALYTICS
                    ],
                    data: {
                        expanded: true,
                    },
                    onClickFn: () => {
                        if (user.hasPermission(ApplicationPermissions.VIEW_ALLOCATE_TO_RESOURCE)) {
                            rulesDialog.openRuleDialog(RuleSection.ResourceSpecification);
                        } else if (user.hasPermission(ApplicationPermissions.VIEW_ALLOCATE_SUPPORT_COST)) {
                            rulesDialog.openRuleDialog(RuleSection.TaskSpecification);
                        }
                    },
                    children: [
                        {
                            path: "allocate-to-resource",
                            lid: "APP._Navigation.AllocateToResource",
                            id: "rules-allocation-of-cost-allocate-to-resource",
                            anonymous: false,
                            permissions: [ApplicationPermissions.VIEW_ALLOCATE_TO_RESOURCE],
                            data: {
                                expanded: true,
                            },
                            onClickFn: () => {
                                rulesDialog.openRuleDialog(RuleSection.ResourceSpecification);
                            },
                            children: [
                                {
                                    path: "",
                                    lid: "APP._Navigation.ResourceSpecification",
                                    id: "rules-allocation-of-cost-allocate-to-resource-resource-specification",
                                    anonymous: false,
                                    permissions: [ApplicationPermissions.VIEW_ALLOCATE_TO_RESOURCE],
                                    onClickFn: () => {
                                        rulesDialog.openRuleDialog(RuleSection.ResourceSpecification);
                                    },
                                },
                                {
                                    path: "",
                                    lid: "APP._Navigation.ResourceTransfer",
                                    id: "rules-allocation-of-cost-allocate-to-resource-resource-transfer",
                                    anonymous: false,
                                    permissions: [ApplicationPermissions.VIEW_ALLOCATE_TO_RESOURCE],
                                    onClickFn: () => {
                                        rulesDialog.openRuleDialog(RuleSection.ResourceTransfer);
                                    },
                                },
                            ],
                        },
                        {
                            path: "allocate-support-cost",
                            lid: "APP._Navigation.AllocateSupportCost",
                            id: "rules-allocation-of-cost-allocate-support-cost",
                            anonymous: false,
                            permissions: [ApplicationPermissions.VIEW_ALLOCATE_SUPPORT_COST],
                            data: {
                                expanded: true,
                            },
                            onClickFn: () => {
                                rulesDialog.openRuleDialog(RuleSection.TaskSpecification);
                            },
                            children: [
                                {
                                    path: "",
                                    lid: "APP._Navigation.TaskSpecification",
                                    id: "rules-allocation-of-cost-allocate-support-cost-task-specification",
                                    anonymous: false,
                                    permissions: [ApplicationPermissions.VIEW_ALLOCATE_SUPPORT_COST],
                                    onClickFn: () => {
                                        rulesDialog.openRuleDialog(RuleSection.TaskSpecification);
                                    },
                                },
                                {
                                    path: "",
                                    lid: "APP._Navigation.SupportCostTransfer",
                                    id: "rules-allocation-of-cost-allocate-support-cost-support-cost-transfer",
                                    anonymous: false,
                                    permissions: [ApplicationPermissions.VIEW_ALLOCATE_SUPPORT_COST],
                                    onClickFn: () => {
                                        rulesDialog.openRuleDialog(RuleSection.SupportCostTransfer);
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "allocation-to-production",
                    lid: "APP._Navigation.AllocationToProduction",
                    id: "rules-allocation-to-production",
                    anonymous: false,
                    permissions: [
                        ApplicationPermissions.VIEW_ALLOCATE_TO_PRODUCTION,
                        ApplicationPermissions.VIEW_PRODUCTION_VALUES,
                        ApplicationPermissions.VIEW_ALLOCATE_TO_PRODUCTION_ANALYTICS
                    ],
                    data: {
                        expanded: true,
                    },
                    onClickFn: () => {
                        if (features.isFeatureEnabled(AppFeatures.SUBTASKS)) {
                            rulesDialog.openRuleDialog(RuleSection.SubtaskSpecification);
                        } else {
                            rulesDialog.openRuleDialog(RuleSection.MapToProduction);
                        }
                    },
                    children: [
                        {
                            path: "allocate-to-production",
                            lid: "APP._Navigation.AllocateToProduction",
                            id: "rules-allocation-to-production-allocate-to-production",
                            anonymous: false,
                            permissions: [ApplicationPermissions.VIEW_ALLOCATE_TO_PRODUCTION],
                            data: {
                                expanded: true,
                            },
                            onClickFn: () => {
                                if (features.isFeatureEnabled(AppFeatures.SUBTASKS)) {
                                    rulesDialog.openRuleDialog(RuleSection.SubtaskSpecification);
                                } else {
                                    rulesDialog.openRuleDialog(RuleSection.MapToProduction);
                                }
                            },
                            children: [
                                {
                                    path: "",
                                    lid: "APP._Navigation.SubtaskSpecification",
                                    id: "rules-allocation-to-production-allocate-to-production-subtask-specification",
                                    anonymous: false,
                                    permissions: [ApplicationPermissions.VIEW_ALLOCATE_TO_PRODUCTION],
                                    onClickFn: () => {
                                        rulesDialog.openRuleDialog(RuleSection.SubtaskSpecification);
                                    },
                                },
                                {
                                    path: "",
                                    lid: "APP._Navigation.MapToProduction",
                                    id: "rules-allcation-to-production-allocate-to-production-map-to-production",
                                    anonymous: false,
                                    permissions: [ApplicationPermissions.VIEW_ALLOCATE_TO_PRODUCTION],
                                    onClickFn: () => {
                                        rulesDialog.openRuleDialog(RuleSection.MapToProduction);
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },


        {
            path: "analytics",
            id: "analytics",
            lid: "APP._Navigation.Analytics",

            children: [
                {
                    path: "activity",
                    lid: "APP._Navigation.ActivityAnalytics",
                    id: "activity-analytics",
                    anonymous: false,
                    permissions: [ApplicationPermissions.VIEW_ACTIVITY_ANALYTICS],
                    matchChildrenByQueryParams: ["layoutId"],
                    data: {
                        helpTooltipId: HelpTooltip.ActivityAnalytics,
                        [FLEXIBLE_PAGE_DATA_KEY]: "activity-analytics"
                    },
                },
                {
                    path: "product",
                    lid: "APP._Navigation.ProductAnalytics",
                    id: "product-analytics",
                    anonymous: false,
                    permissions: [ApplicationPermissions.VIEW_PRODUCT_ANALYTICS],
                    matchChildrenByQueryParams: ["layoutId"],
                    data: {
                        helpTooltipId: HelpTooltip.ProductAnalytics,
                        [FLEXIBLE_PAGE_DATA_KEY]: "product-analytics"
                    },
                },
                {
                    path: "encounter",
                    lid: "APP._Navigation.EncounterAnalytics",
                    id: "encounter-analytics",
                    anonymous: false,
                    permissions: [ApplicationPermissions.VIEW_ENCOUNTER_ANALYTICS],
                    matchChildrenByQueryParams: ["layoutId"],
                    data: {
                        helpTooltipId: HelpTooltip.EncounterAnalytics,
                        [FLEXIBLE_PAGE_DATA_KEY]: "encounter-analytics"
                    },
                },
            ]
        },

        {
            path: "settings",
            lid: "APP._Navigation.Settings",
            id: "settings",
            noNumber: true,
            children: [
                // {
                //     path: "user-settings",
                //     id: "user-settings",
                //     lid: "APP._Navigation.UserSettings",
                //     permissions: [ApplicationPermissions.USER_SETTINGS]
                // },
                {
                    path: "scenario-settings",
                    id: "scenario-settings",
                    lid: "APP._Navigation.ScenarioSettings",
                    permissions: [ApplicationPermissions.VIEW_ALL_SCENARIO_SETTINGS]
                }
            ]
        },
        {
            path: "exports",
            lid: "APP._Navigation.Exports",
            id: "exports",
            noNumber: true,
            children: [
                {
                    path: "exports",
                    lid: "APP._Navigation.SettingsGeneralExports",
                    id: "exports-exports",
                    permissions: [ApplicationPermissions.EXPORTS],
                },
            ],
        },

    ] as INavNode[];
};


export const rebuildNavigationSubject = new Subject<void>();

export const navigation = (features: ILgFeatures, rulesDialog: RulesDialogService, layoutsNavigationService: IFlexibleLayoutsNavigation, user: IUser) =>
    merge(
        rebuildNavigationSubject,
        features.onChanged,
        layoutsNavigationService.reloadLayouts$
    ).pipe(
        layoutsNavigationService.addFlexibleLayouts(navTree(rulesDialog, features, user)),
        map(navigation => {
            const hidePagesFeature = features.getFeature(AppFeatures.HIDE_PAGES);
            const configuration = hidePagesFeature.configuration as HidePagesFeatureConfiguration;
            const hiddenPages = (configuration && configuration.pages) ?? [];

            // Hide subtask specification section if subtasks feature is disabled
            if (!features.isFeatureEnabled(AppFeatures.SUBTASKS)) {
                hiddenPages.push("rules-allocation-to-production-allocate-to-production-subtask-specification");
            }

            if (_.isEmpty(hiddenPages)) return navigation;

            return filterNavNodes(navigation, hiddenPages);
        })
    );


function filterNavNodes(nodes: INavNode[], pagesToExclude: string[]): INavNode[] {
    const res: INavNode[] = [];
    for (let node of nodes) {
        if (_.includes(pagesToExclude, node.id)) continue;

        if (!_.isEmpty(node.children)) {
            node = {
                ...node,
                children: filterNavNodes(node.children, pagesToExclude)
            };
        }

        res.push(node);
    }
    return res;
}
