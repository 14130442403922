import { Component, inject } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from "@angular/router";
import localeNl from "@angular/common/locales/nl";
import localeFi from "@angular/common/locales/fi";
import localeSv from "@angular/common/locales/sv";
import localeEn150 from "@angular/common/locales/en-150";
import localeNb from "@angular/common/locales/nb";
import localeCy from "@angular/common/locales/cy";
import localeEnGb from "@angular/common/locales/en-GB";
import localeDe from "@angular/common/locales/de";
import localeDa from "@angular/common/locales/da";
import localeZu from "@angular/common/locales/zu";

import { LG_APP_CONFIGURATION, LgMatTrackingService, } from "@logex/framework/lg-application";
import { LgLoaderService } from "@logex/framework/lg-layout";

import { AppConfiguration, MatomoConfiguration } from "@logex/fadp";


@Component({
    selector: "app-root",
    template: `
        <router-outlet></router-outlet>
    `,
})
export class AppComponent {

    appConfiguration = inject<AppConfiguration>(LG_APP_CONFIGURATION);
    private _loaderService = inject(LgLoaderService);
    private _matomoTracker = inject(LgMatTrackingService);
    private _router = inject(Router);

    constructor() {
        registerLocaleData(localeNl);
        registerLocaleData(localeFi);
        registerLocaleData(localeSv);
        registerLocaleData(localeEnGb);
        registerLocaleData(localeEn150);
        registerLocaleData(localeNb);
        registerLocaleData(localeCy);
        registerLocaleData(localeDe);
        registerLocaleData(localeDa);
        registerLocaleData(localeZu);

        this._router.events.subscribe(ev => {
            if (ev instanceof RouteConfigLoadStart) {
                this._loaderService.show();
            } else if (ev instanceof RouteConfigLoadEnd) {
                this._loaderService.hide();
            }
        });

        // Initialize trackers
        if (this.appConfiguration.appSettings.matomo != null) {
            this._matomoTracker.init(new MatomoConfiguration(this.appConfiguration.appSettings.matomo));
        }
    }

}
