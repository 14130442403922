import { inject, Injectable } from "@angular/core";
import { LG_APP_INFO } from "@logex/framework/lg-application";
import { APP_HELP_LINKS, ExtendedSidebarHelpLinks } from "../app-help";
import { APP_BANNER_CONFIG, IAppBannerConfig } from "@shared/types/app-banner";
import { AppStartupServiceBase, IAppSettings } from "@logex/fadp";
import { firstValueFrom } from "rxjs";

declare let APP_VERSION: string;

// ----------------------------------------------------------------------------------
// Configuration
export interface AppSettings extends IAppSettings {
    banner: IAppBannerConfig;
}


// ----------------------------------------------------------------------------------
//
@Injectable({ providedIn: "root" })
export class AppStartupService extends AppStartupServiceBase<AppSettings> {

    private _appHelpLinks = inject<ExtendedSidebarHelpLinks>(APP_HELP_LINKS);
    private _appInfo = inject(LG_APP_INFO);
    private _appBannerConfig = inject<IAppBannerConfig>(APP_BANNER_CONFIG);

    protected async _initialize(): Promise<boolean> {

        // In case user's landing with the old-style hash routing, redirect to the target page
        if (window.location.href.includes("#!")) {
            window.location.href = window.location.href.split("#!").pop();
        }

        if (!await super._initialize()) return false;

        this._configureAppInfo();
        this._configureAppBanner();

        Object.assign(this._appHelpLinks, this._appConfiguration.appSettings.helpLinks);

        return true;
    }

    protected _getBasePath(): string {
        return "/";
    }


    private _configureAppInfo(): void {
        Object.assign(this._appInfo, {
            productId: "costing",
            versionNumber: APP_VERSION, // version will be taken from from Costing.Application.csproj
            toolInstanceName: this._appConfiguration.instance,
            environment: this._appConfiguration.environment,
            userManualUrl: "https://support.logex.nl/financieel-besturingsmodel/kostprijsmodel",
        });
    }

    private _configureAppBanner(): void {
        Object.assign(this._appBannerConfig, {
            heading: this._appConfiguration.appSettings.banner?.heading,
            body: this._appConfiguration.appSettings.banner?.body,
            buttonText: this._appConfiguration.appSettings.banner?.buttonText,
            buttonLink: this._appConfiguration.appSettings.banner?.buttonLink,
        });
    }
}
